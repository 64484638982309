import { OPTIONS_VIEWS } from './useView'

export type Module = {
  id: string
  name: string
  description: string
  color: string
  icon: `i-mdi-${string}`
  disabled: boolean
}

export const MODULES: Module[] = [
  {
    id: 'admin',
    name: 'Administración',
    description: 'Gestión del sistema, administración de roles y configuraciones.',
    color: 'gray',
    icon: 'i-mdi-cog',
    disabled: false,
  },
  {
    id: 'content',
    name: 'Contenidos',
    description: 'Gestión de contenidos estático en diferentes idiomas.',
    color: 'black',
    icon: 'i-mdi-format-text',
    disabled: false,
  },
  {
    id: 'docs',
    name: 'Documentación',
    description: 'Documentación del sistema y manuales de usuario.',
    color: 'yellow',
    icon: 'i-mdi-book',
    disabled: false,
  },
  {
    id: 'finance',
    name: 'Finanzas',
    description: 'Gestión de caja, presupuestos y reportes financieros.',
    color: 'green',
    icon: 'i-mdi-bank',
    disabled: false,
  },
  {
    id: 'hr',
    name: 'Recursos Humanos',
    description: 'Gestión de empleados, nómina y procesos de contratación.',
    color: 'pink',
    icon: 'i-mdi-human',
    disabled: true,
  },
  {
    id: 'inventory',
    name: 'Inventarios',
    description: 'Gestión de productos, almacenes y control de inventarios.',
    color: 'blue',
    icon: 'i-mdi-warehouse',
    disabled: true,
  },
  {
    id: 'management',
    name: 'Dirección',
    description: 'Gestión empresarial, indicadores de gestión y reportes.',
    color: 'blue',
    icon: 'i-mdi-finance',
    disabled: false,
  },
  {
    id: 'production',
    name: 'Producción',
    description: 'Gestión de operaciones, proceso de producción y órdenes de producción.',
    color: 'black',
    icon: 'i-mdi-factory',
    disabled: true,
  },
  {
    id: 'purchases',
    name: 'Compras',
    description: 'Gestión de proveedores, proceso de compras y órdenes de compra.',
    color: 'red',
    icon: 'i-mdi-cart',
    disabled: false,
  },
  {
    id: 'sales',
    name: 'Ventas',
    description: 'Gestión de clientes, proceso de venta y cotizaciones.',
    color: 'purple',
    icon: 'i-mdi-tag',
    disabled: false,
  },
]

// view:

export const VIEW_SETTINGS_MODULES: ViewSettings<Module> = {
  tableName: 'modules',
  views: OPTIONS_VIEWS.filter(view => ['cards'].includes(view.value)),
  selectedView: 'cards',
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Descripción',
    },
    {
      key: 'disabled',
      label: 'Deshabilitado',
      sortable: true,
    },
  ],
  visibleColumns: [
    'actions',
    'name',
    'description',
    'disabled',
  ],
}

// NOTE: use experimental.asyncContext once support for Cloudflare lands (see https://github.com/unjs/nitro/issues/1171)
// apparently this requires setting Cloudflare Workers nodejs_compat compatibility flag (see https://github.com/unjs/unenv/issues/280)
export const useModules = () => {
  const { user } = useUserSession()

  // data:

  const modules = computed(() => MODULES
    .filter((mod) => {
      const ids = Object.keys(user.value?.modules ?? {})
      return ids?.includes(mod.id)
    }) // filter out modules not registered at user.modules
    .map((mod) => {
      const hasAccess = mod.id && user.value?.modules?.[mod.id as keyof User['modules']]?.enabled
      return { ...mod, disabled: mod.disabled || !hasAccess }
    }), // apply disabled state to modules without 'enabled: true'
  )

  const query = { $columns: ['id', 'name', 'image'] } // props required for options

  const $users = useFetch<User[]>('/db/users', { query, default: () => [] })

  // options:

  // maps:

  const MAPS = {
    moduleId: computed(() => toMapByKey(modules.value, 'id')),
    userId: computed(() => toMapByKey($users.data.value, 'id')),
  } as const

  return {
    modules,
    $users,
    MAPS,
  }
}
